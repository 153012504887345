input[type='search'] {
  &::-webkit-search-cancel-button {
    -webkit-appearance: none;
    // height: 24px;
    // width: 30px;
    // background: url('/assets/images/icons/icon-close.svg') no-repeat 50% 50%;
    background-size: contain;
    opacity: 1;
    cursor: pointer;
  }
}
