.mat-mdc-paginator-container {
  @media only screen and (max-width: 1000px) {
    gap: 10px;
  }
}

.pagination-wrapper {
  justify-content: flex-end;
  .font-12 {
    input {
      font-size: var(--mat-paginator-select-trigger-text-size) !important;
    }
  }

  @media only screen and (max-width: 550px) {
    justify-content: center;
    margin-top: 1rem;

    .mat-mdc-paginator-container {
      justify-content: center;

      .mat-mdc-paginator-page-size {
        margin-right: 0;
      }
    }
  }
}
