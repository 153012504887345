.material-symbols-outlined {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;

  &.fill {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
}

.material-symbols-rounded {
  font-variation-settings: 'FILL' 0, 'wght' 400, 'GRAD' 0, 'opsz' 24;

  &.fill {
    font-variation-settings: 'FILL' 1, 'wght' 400, 'GRAD' 0, 'opsz' 24;
  }
}

/* Icon */
/* Icon Size 20px */
.icon {
  font-size: 1.25rem;
}

.icon-16 {
  font-size: 1rem;
}

.icon-20 {
  font-size: 1.25rem;
}

.icon-24 {
  font-size: 1.5rem;
}

.icon-32 {
  font-size: 2rem;
}

.icon-70 {
  font-size: 70px;
}

/* Icon Grey */
.icon-grey {
  color: var(--neutral-400);
}

.icon-dark-grey {
  color: var(--neutral-500);
}

.icon-dark {
  color: var(--neutral-950);
}

.icon-blue {
  color: var(--brand-blue);
}

.icon-brand-blue-700 {
  color: var(--brand-blue-700);
}

.icon-blue-700 {
  color: var(--blue-700);
}

.icon-white {
  color: var(--white);
}

.icon-red {
  color: var(--red-600);
}

.icon-orange {
  color: var(--orange-600);
}

.name-channel {
  img {
    max-height: 1em !important;
  }
}
