/* width */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: var(--neutral-100, #f5f5f5);
  border-radius: 7px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: var(--neutral-300, #d4d4d4);
  border-radius: 7px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: var(--neutral-400);
}
