.cdk-overlay-container:has(> .input-tag-backdrop) {
  .cdk-overlay-pane:has(> .mat-menu-above) {
    margin-bottom: -20px;
  }
  .cdk-overlay-pane:has(> .mat-menu-below) {
    margin-top: -20px;
  }
}

.cdk-overlay-container:has(> .time-picker-backdrop) {
  // .cdk-overlay-pane:has(> .mat-menu-above) {
  //   margin-bottom: -20px;
  // }
  .cdk-overlay-pane:has(> .mat-menu-below) {
    margin-top: -20px;
  }
}

.mat-mdc-menu-panel {
  box-shadow: 0px 4px 12px 2px #0000001f !important;
}

:root {
  --mat-menu-container-shape: 8px;
}

.business-unit {
  font-size: 14px;
  font-weight: 400;
  line-height: 22.9px;
  color: var(--neutral-400);
  padding: 0 16px;
}
