$colors: (
  '--Primary-Black': #171717,

  '--Secondary-Light-Blue': #e8f6fc,
  '--Secondary-Blue': #1a9fd6,
  '--Secondary-Dark-blue': #106487,

  '--Neutral-100': #f5f5f5,
  '--Neutral-200': #e5e5e5,
  '--neutral-300-line': #d4d4d4,
  '--Neutral-400': #adadad,
  '--Neutral-500': #737373,
  '--Neutral-700': #404040,
  '--Neutral-800': #262626,
  '--Neutral-950': #0a0a0a,

  '--Neutral-300-Line': #d4d4d4,

  '--Error-Light': #fef2f2,
  '--Error-Medium': #ef4444,
  '--Error-Dark': #b91c1c,

  '--Warning-Light': #fefce8,
  '--Warning-Medium': #fbbf24,
  '--Warning-Dark': #b45309,

  '--Success-Light': #f0fdf4,
  '--Success-Medium': #16a34a,
  '--Success-Dark': #15803d,

  '--Info-Light': #eff6ff,
  '--Info-Medium': #60a5fa,
  '--Info-Dark': #1f6cea,

  '--Neutral-4': #a3a3a3,

  '--White': #ffffff,
);

:root {
  @each $name, $value in $colors {
    #{$name}: #{$value};
  }
}

@each $name, $value in $colors {
  .color#{$name} {
    color: $value;
  }

  .color#{$name}--f {
    color: $value !important;
  }
}
