// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

@import 'assets/fonts/font.css';
@import './styles/global.scss';
@import './styles/global-public.scss';
@import './styles/global-public-bhb.scss';
@import './styles/scrollbar.scss';
@import './styles/icon.scss';
@import './styles/print.scss';
@import './styles/tabs.scss';
@import './styles/layout.scss';
@import './styles/table.scss';
@import './styles/button.scss';
@import './styles/snackbar.scss';
@import './styles/mat-custom.scss';
@import './styles/login.scss';
@import './styles/modal.scss';
@import './styles/tooltip.scss';
@import './styles/validate.scss';
@import './styles/menu.scss';
@import './styles/select.scss';
@import './styles/drag-and-drop.scss';
@import './styles/paginator.scss';
@import './styles/input.scss';

// $my-typography: mat.define-typography-config(
//   $font-family: "Helvetica Neue",
// );
// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$brandname-hunter-order-manage-primary: mat.define-palette(mat.$indigo-palette);
// $brandname-hunter-order-manage-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$brandname-hunter-order-manage-accent: mat.define-palette(mat.$light-blue-palette);

// The warn palette is optional (defaults to red).
$brandname-hunter-order-manage-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$brandname-hunter-order-manage-theme: mat.define-light-theme(
  (
    color: (
      primary: $brandname-hunter-order-manage-primary,
      accent: $brandname-hunter-order-manage-accent,
      warn: $brandname-hunter-order-manage-warn,
    ),
    typography:
      mat.define-typography-config(
        $font-family: 'Kanit',
      ),
    density: 0,
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($brandname-hunter-order-manage-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: 'Kanit';
  font-size: 16px;
  line-height: 1.5;
}
