.table {
  width: 100%;
  font-size: 0.875rem;
  font-weight: 400;

  th {
    padding: 0.75rem 1rem;
    font-weight: 400;
    position: sticky;
    top: 0;
    z-index: 1;
    background-color: var(--brand-blue-50);
    color: var(--brand-blue-700);
    border-bottom: 0;
    vertical-align: middle;
  }

  tr {
    height: 100%;

    td {
      vertical-align: middle;
      height: 100%;
    }
  }

  tbody {
    tr {
      td {
        padding: 0.75rem 1rem;
        border-color: var(--neutral-200);
        word-break: break-word;
      }
    }
  }

  th.colum-checkbox,
  td.colum-checkbox {
    padding: 0.25rem 1rem;
  }

  th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
  }
  th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
  }
}

.text-ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table.table-no-border {
  th {
    font-size: 16px;
    font-weight: 600;
    padding: 0.25rem 0;
    color: var(--neutral-950);
    background-color: var(--white) !important;
  }
  td {
    border-color: transparent;
    padding: 0.25rem 0;
  }
}

.table-responsive-no-border {
  overflow: auto;
}

.table-fixed-column {
  table-layout: fixed;
  border-collapse: separate;
  border-spacing: 0;

  tr {
    .fixed {
      position: sticky;
      right: 0;

      &.th-fixed {
        z-index: 2;
      }
    }
  }

  .box-shadow-left {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    clip-path: inset(0px 0px 0px -15px);
  }

  .box-shadow-right {
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.1);
    clip-path: inset(0px -15px 0px 0px);
  }
}

.table-responsive {
  overflow: auto;
  min-height: 250px;
  max-height: 430px;
}

.table-responsive-detail {
  overflow: auto;
  min-height: 100px;
  max-height: 430px;
}

.table-responsive-no-height {
  overflow: auto;
  min-height: 100px;
}

.table-main-wrapper {
  min-height: 420px;
  max-height: 800px;
}

.table-main-tabs-wrapper {
  min-height: 420px;
  max-height: 800px;
}

.table-checkbox {
  th {
    padding: 0.25rem 1rem;
  }

  tbody {
    tr {
      td {
        padding: 0.6rem 1rem;
      }
    }
  }
}

.img-table {
  width: 60px;
  height: 60px;
  object-fit: contain;
}

.tfoot-fixed {
  position: sticky;
  bottom: 0;
}
.tr-sum-total {
  td {
    padding: 12px !important;
    height: 60px !important;
    background: var(--neutral-50) !important;
  }
}
.tr-sum-total-reprot {
  @extend .tr-sum-total;
  td {
    padding: 0.75rem 1rem !important;
  }
}
