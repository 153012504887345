$colors: (
  '--Primary-Lotus-Petals': #c6858f,
  '--Primary-Ruby': #9d2235,
  '--Primary-Black-Jasper': #020000,

  '--Secondary-Blue': #1a9fd6,
  '--Secondary-Dark-blue': #106487,

  '--Neutral-100': #f5f5f5,
  '--Neutral-200': #e5e5e5,
  '--neutral-300-line': #d4d4d4,
  '--Neutral-400': #adadad,
  '--Neutral-500': #737373,
  '--Neutral-700': #404040,
  '--Neutral-800': #262626,
  '--Neutral-950': #0a0a0a,

  '--Neutral-300-Line': #d4d4d4,

  '--Error-Light': #fef2f2,
  '--Error-Medium': #ef4444,
  '--Error-Dark': #b91c1c,

  '--Warning-Light': #fefce8,
  '--Warning-Medium': #fbbf24,
  '--Warning-Dark': #b45309,

  '--Success-Light': #f0fdf4,
  '--Success-Medium': #16a34a,
  '--Success-Dark': #15803d,

  '--Info-Light': #eff6ff,
  '--Info-Medium': #60a5fa,
  '--Info-Dark': #1f6cea,

  '--Neutral-4': #a3a3a3,
);

:root {
  @each $name, $value in $colors {
    #{$name}: #{$value};
  }

  --image-aspect-ratio-y: 3 / 4; //image product Vertical
  --image-aspect-ratio-x: 4 / 3; //image product Horizontal

  --gap-card-product: 8px;
  --gap-card-products: 16px 8px;
  --padding-card-products: 8px 0px;
  --padding-card-products-x: 0 8px;
  --padding-card-products-y: 8px 0;

  --gap-card-product-sm: var(--gap-card-product);
  --gap-card-products-sm: var(--gap-card-products);
  --padding-card-products-sm: var(--padding-card-products);
  --padding-card-products-x-sm: var(--padding-card-products-x);
  --padding-card-products-y-sm: var(--padding-card-products-y);

  --gap-card-product-md: var(--gap-card-product);
  --gap-card-products-md: var(--gap-card-products);
  --padding-card-products-md: var(--padding-card-products);
  --padding-card-products-x-md: var(--padding-card-products-x);
  --padding-card-products-y-md: var(--padding-card-products-y);

  --padding-container: 56px;
  --padding-container-mobile: 24px;
  --product-card-padding: 8px;
  --product-card-padding-container-25: calc(var(--padding-container) + var(--product-card-padding));
  --product-card-padding-container-50: calc(var(--padding-container) + var(--product-card-padding));

  @media screen and (min-width: 576px) {
    --product-card-padding-sm: 16px;

    --gap-card-product-sm: 32px;
    --gap-card-products-sm: 32px;
    --padding-card-products-sm: 32px 0;
    --padding-card-products-x-sm: 0 32px;
    --padding-card-products-y-sm: 32px 0;
  }

  @media screen and (min-width: 768px) {
    --product-card-padding-md: 16px;

    --gap-card-product-md: 32px;
    --gap-card-products-md: 32px;
    --padding-card-products-md: 32px 0;
    --padding-card-products-x-md: 0 32px;
    --padding-card-products-y-md: 32px 0;
  }
}

@each $name, $value in $colors {
  .color#{$name} {
    color: $value;
  }

  .color#{$name}--f {
    color: $value !important;
  }
}

@for $i from 1 through 20 {
  .font-#{$i} {
    font-size: #{$i}px;
  }
}

@media screen and (min-width: 576px) {
  @for $i from 1 through 20 {
    .sm\:font-#{$i} {
      font-size: #{$i}px;
    }
  }
}

@media screen and (min-width: 768px) {
  @for $i from 1 through 20 {
    .md\:font-#{$i} {
      font-size: #{$i}px;
    }
  }
}

@media screen and (min-width: 992px) {
  @for $i from 1 through 20 {
    .lg\:font-#{$i} {
      font-size: #{$i}px;
    }
  }
}

@media screen and (min-width: 1200px) {
  @for $i from 1 through 20 {
    .xl\:font-#{$i} {
      font-size: #{$i}px;
    }
  }
}

.text-underline {
  text-decoration: underline;
}

.text-underline-offset-1-5 {
  text-decoration: underline;
  text-underline-offset: 1.5px;
}

.text-underline-offset-2 {
  text-decoration: underline;
  text-underline-offset: 2px;
}

.text-underline-offset-5 {
  text-decoration: underline;
  text-underline-offset: 5px;
}

.hover\:text-underline-offset-1-5 {
  &:hover {
    @extend .text-underline-offset-1-5;
  }
}

.hover\:text-underline-offset-2 {
  &:hover {
    @extend .text-underline-offset-2;
  }
}

.hover\:text-underline-offset-5 {
  &:hover {
    @extend .text-underline-offset-5;
  }
}

.padding-container-top {
  padding-top: var(--padding-container);
}

.padding-container-bottom {
  padding-bottom: var(--padding-container);
}
