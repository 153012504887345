.mat-mdc-tooltip {
  .mdc-tooltip__surface {
    white-space: pre-line;
    max-width: 500px;
  }

  &.tooltip-black {
    .mdc-tooltip__surface {
      background-color: var(--neutral-950) !important;
    }
  }

  &.tooltip-customer-tier {
    .mdc-tooltip__surface {
      max-width: 500px;
    }
  }
}

:root {
  --mdc-plain-tooltip-supporting-text-line-height: 22.9px;
  --mdc-plain-tooltip-supporting-text-size: 14px;
  --mdc-plain-tooltip-supporting-text-weight: 400;
  --mdc-plain-tooltip-supporting-text-tracking: 0;
}
