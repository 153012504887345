.mat-mdc-dialog-container {
  .mdc-dialog__container {
    .mat-mdc-dialog-surface {
      border-radius: 0.5rem;
    }

    .dialog-title {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid var(--neutral-200);
      padding: 1rem 1.25rem;
      font-size: 1.125rem;
      font-weight: 600;
      color: var(--black);

      + .mat-mdc-dialog-content {
        padding-top: 1.25rem;
      }

      .btn-icon {
        width: 20px;
        height: 20px;
        padding: 0;
      }
    }

    .dialog-title-head {
      display: flex;
      justify-content: start;
      width: 100%;
    }

    .mat-mdc-dialog-content {
      padding: 1.25rem;
      font-size: 1rem;
      color: var(--neutral-950);
      word-break: break-word;
    }

    .mat-mdc-dialog-actions {
      padding: 1rem 1.25rem;
      border-top: 1px solid var(--neutral-200);

      .footer-button-wrapper {
        column-gap: 0.75rem;
      }
    }
  }
}

.modal-content {
  width: 532px;

  @media only screen and (max-width: 600px) {
    width: 310px;
    max-width: calc(100vw - 80px);
  }
}

.modal-content-table {
  width: 1000px;

  @media only screen and (max-width: 600px) {
    width: 310px;
  }
}

@media only screen and (max-width: 600px) {
  .cdk-global-overlay-wrapper {
    .cdk-overlay-pane {
      &.mat-mdc-dialog-panel {
        max-width: 90vw;
      }
    }
  }
}
