.mat-mdc-snack-bar-container {
  border-radius: 8px !important;
  margin: 72px 16px 0 0 !important;
  position: static;

  .mdc-snackbar__surface {
    padding-right: 0;
    border-radius: 8px !important;
  }

  .mdc-snackbar__label {
    padding: 0 16px ;
    border-radius: 8px !important;
  }

  &.snack-bar-success-wrapper {
    .snackbar-success {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--neutral-950);
      border: 1px solid var(--green-600);
      background-color: var(--green-50);
      padding: 8px 16px;
    }
  }

  &.snack-bar-fail-wrapper {
    .snackbar-fail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: var(--neutral-950);
      border: 1px solid var(--red-600);
      background-color: var(--red-50);
      padding: 8px 16px;
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}
