.select-sort {
  max-height: 50vh !important;
}

.select-suffix {
  height: 40px;
  width: 30px;
}

.select-custom-arrow {
  .mat-mdc-select-arrow-wrapper {
    display: none !important;
  }
}

.mat-mdc-select-panel {
  @media only screen and (min-width: 600px) {
    max-height: 390px !important;
  }
}
